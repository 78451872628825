$(".partnersDataTable").show(function () {

    var sAjaxSource = 'partners/listing';
    var aoColumns = [
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count",
        },
        {
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "name",
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "logo_path",
            "render": function (
                    mData, type,
                    row) {
                return '<img width="60" src="/file/' + mData + '"/>';
            }
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "created"
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "action",
            "render": function (
                    mData, type,
                    row) {
                if (row.is_active == true) {
                    var title = 'Block';
                    var className = 'fa-toggle-on';
                } else if (row.is_active == false) {
                    var title = 'Unblock';
                    var className = 'fa-toggle-off';
                }
                return '<a title="Edit" class="action-icons" href="/partners/' + row.id + '/edit"><i class="fa fa-edit"></i></a> ' +
                        '<a title="'+title+'" class="action-icons" href="#" ><i id="' + row.id + '" status="1" partner-id ="' + row.id + '" class="fa ' + className + ' blockUnblock"></i></a> ' +
                        '<a title="Delete" class="action-icons delete-record" href="#" partner-id ="' + row.id + '"><i class="fa fa-trash"></i></a>';
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns);
    $("#create-new").insertBefore($(".dataTables_filter"));
    $('#create-new,.dataTables_filter').wrapAll('<div class="dataTables_wrapper">');

    $(document).on("click", ".blockUnblock", function () {
        var old_is_active = $(this).attr('status');
        var partner_id = $(this).attr('partner-id');

        var is_active = 1;
        var status = 'unblock';
        if (old_is_active == 1) {
            is_active = 0;
            var status = 'block';
        }
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        $(this).attr('status', is_active);

        bootbox.dialog({
            message: "Are you sure you want to " + status + " this partner?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/partners/status/' + partner_id + '/' + is_active,
                            //data: requestData,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                if (is_active == 1) {
                                    $("#"+partner_id).removeClass('fa-toggle-off');
                                    $("#"+partner_id).addClass('fa-toggle-on');
                                    $("#"+partner_id).attr('title', 'Block');
                                } else {
                                    $("#"+partner_id).removeClass('fa-toggle-on');
                                    $("#"+partner_id).addClass('fa-toggle-off');
                                    $("#"+partner_id).attr('title', 'Unblock');
                                }
                            }
                        }).done(function (data) {

                        });
                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                    callback: function () {
                        $('#' + partner_id).attr('status', old_is_active);
                        if (old_is_active == 1) {
                            $('#' + partner_id).removeClass('fa-toggle-off');
                            $('#' + partner_id).addClass('fa-toggle-on');
                            $("#"+partner_id).attr('title', 'Block');
                        } else {
                            $('#' + partner_id).removeClass('fa-toggle-on');
                            $('#' + partner_id).addClass('fa-toggle-off');
                            $("#"+partner_id).attr('title', 'Unblock');
                        }
                    }
                }
            }
        });

    });

    $(document).on("click", ".delete-record", function () {
        var old_is_active = $(this).attr('status');
        var partner_id = $(this).attr('partner-id');

        var is_active = 2;
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };

        bootbox.dialog({
            message: "Are you sure you want to delete this partner?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/partners/status/' + partner_id + '/' + is_active,
                            //data: requestData,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false
                        }).done(function (data) {
                            window.location = '/partners/';
                        });

                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                }
            }
        });

    });
});
$('#logo_path').on('change', function () {
    $('#partner-image-preview').html('<img class="video-preview" src="" width="35%" height="30%"  id="image"/>');
      var file = document.querySelector('#logo_path').files[0];
      var reader  = new FileReader();
    reader.addEventListener("load", function () {
            document.getElementById("image").src = reader.result;
      }, false);
      if (file) {
            reader.readAsDataURL(file);
      }
});