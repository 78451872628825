$(".portalManagementDataTable").show(function () {
    var sAjaxSource = '/portal-management/listing';
    var aoColumns = [
        {
            "sWidth": "4%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count"
        },
        {
            "sWidth": "20%",
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "section_name"
        },
        {
            "sWidth": "15%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "in_menu",
            "render": function (mData, type, row) {
                if (mData == true) {
                    var htmlBtn = '<a title="Do not show in menu" class="action-icons" href="javascript:void(0)"><i portal-id ="' + row.id + '" status="1" class="fa fa-toggle-on inMenu"></i></a>';
                } else if (mData == false) {
                    var htmlBtn = '<a title="Show in menu" class="action-icons" href="javascript:void(0)"><i portal-id ="' + row.id + '" status="0" class="fa fa-toggle-off inMenu"></i></a>';
                }
                return htmlBtn;
            }
        },
        {
            "sWidth": "15%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "is_active",
            "render": function (mData, type, row) {
                if (mData == true) {
                    var htmlBtn = '<a title="Block" class="action-icons" href="javascript:void(0)"><i id ="' + row.id + '" portal-id ="' + row.id + '" status="1" class="fa fa-toggle-on blockUnblock"></i></a>';
                } else if (mData == false) {
                    var htmlBtn = '<a title="Unblock" class="action-icons" href="javascript:void(0)"><i id ="' + row.id + '" portal-id ="' + row.id + '" status="0" class="fa fa-toggle-off blockUnblock"></i></a>';
                }
                return htmlBtn;
            }
        },
        {
            "sWidth": "15%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "id",
            "render": function (mData, type, row) {
                var htmlBtn = '<a title="Edit" class="action-icons" href="/portal-management/' + row.id + '/edit"><i class="fa fa-edit"></i></a>';
                return htmlBtn;
            }
        },
        {
            "sWidth": "15%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "id",
            "render": function (mData, type, row) {
                var htmlBtn = '<a title="Down" class="action-icons" href="/portal-management/set-order/' + row.id + '/down"><i class="glyphicon glyphicon-menu-down"></i></a> ' +
                        '<a title="Up" class="action-icons" href="/portal-management/set-order/' + row.id + '/up"><i class="glyphicon glyphicon-menu-up"></i></a>';
                return htmlBtn;
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns, null, false, false);

    $(document).on("click", ".blockUnblock", function () {
        var old_is_active = $(this).attr('status');
        var portal_id = $(this).attr('portal-id');

        var is_active = 1;
        var status = 'activate';
        if (old_is_active == 1) {
            is_active = 0;
            var status = 'deactivate';
        }
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        $(this).attr('status', is_active);

        bootbox.dialog({
            message: "Are you sure you want to " + status + " this section?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/portal-management/status/' + portal_id + '/' + is_active + '/blockUnblock',
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                if (is_active == 1) {
                                    $("#"+portal_id).removeClass('fa-toggle-off');
                                    $("#"+portal_id).addClass('fa-toggle-on');
                                    $("#"+portal_id).attr('title', 'Block');
                                } else {
                                    $("#"+portal_id).removeClass('fa-toggle-on');
                                    $("#"+portal_id).addClass('fa-toggle-off');
                                    $("#"+portal_id).attr('title', 'Unblock');
                                }
                            }
                        }).done(function (data) {

                        });
                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                    callback: function () {
                        $('#' + portal_id).attr('status', old_is_active);
                        if (old_is_active == 1) {
                            $('#' + portal_id).removeClass('fa-toggle-off');
                            $('#' + portal_id).addClass('fa-toggle-on');
                            $("#"+portal_id).attr('title', 'Block');
                        } else {
                            $('#' + portal_id).removeClass('fa-toggle-on');
                            $('#' + portal_id).addClass('fa-toggle-off');
                            $("#"+portal_id).attr('title', 'Unblock');
                        }
                    }
                }
            }
        });
    });

    $(document).on("click", ".inMenu", function () {
        var old_in_menu = $(this).attr('status');
        var portal_id = $(this).attr('portal-id');

        var in_menu = 1;
        if (old_in_menu == 1) {
            in_menu = 0;
        }
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        $(this).attr('status', in_menu);
        if (in_menu == 1) {
            $(this).removeClass('fa-toggle-off');
            $(this).addClass('fa-toggle-on');
            $(this).attr('title', 'Do not show in menu');
        } else {
            $(this).removeClass('fa-toggle-on');
            $(this).addClass('fa-toggle-off');
            $(this).attr('title', 'Show in menu');
        }

        $.ajax({
            url: '/portal-management/status/' + portal_id + '/' + in_menu + '/inMenu',
            headers: header,
            type: "GET",
            contentType: false,
            processData: false
        }).done(function (data) {

        });

    });
});