$(".episodesDataTable").show(function () {
    var sAjaxSource = '/episodes/listing';
    var aoColumns = [
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count",
        },
        {
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "name",
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "duration"
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "created"
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "is_active",
            "render": function (mData, type, row) {
                if (mData == true) {
                    var htmlBtn = '<a title="Edit" class="action-icons" href="/episodes/' + row.id + '/edit"><i class="fa fa-edit"></i></a> ' +
                            '<a title="Block" class="action-icons" href="javascript:void(0)"><i id ="' + row.id + '" episode-id ="' + row.id + '" status="1" class="fa fa-toggle-on blockUnblock"></i></a> ' +
                            '<a title="Delete" class="action-icons" href="javascript:void(0)"><i episode-id="' + row.id + '" class="fa fa-trash deleteEpisode"></i></a>';
                } else if (mData == false) {
                    var htmlBtn = '<a title="Edit" class="action-icons" href="/episodes/' + row.id + '/edit"><i class="fa fa-edit"></i></a> ' +
                            '<a title="Unblock" class="action-icons" href="javascript:void(0)"><i id ="' + row.id + '" episode-id ="' + row.id + '" status="0" class="fa fa-toggle-off blockUnblock"></i></a> ' +
                            '<a title="Delete" class="action-icons" href="javascript:void(0)"><i episode-id="' + row.id + '" class="fa fa-trash deleteEpisode"></i></a>';
                }
                return htmlBtn;
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns);
    $("#create-new").insertBefore($(".dataTables_filter"));
    $('#create-new,.dataTables_filter').wrapAll('<div class="dataTables_wrapper">');

    $(document).on("click", ".blockUnblock", function () {
        var old_is_active = $(this).attr('status');
        var episode_id = $(this).attr('episode-id');

        var is_active = 1;
        var status = 'unblock';
        if (old_is_active == 1) {
            is_active = 0;
            var status = 'block';
        }
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        $(this).attr('status', is_active);

        bootbox.dialog({
            message: "Are you sure you want to " + status + " this episode?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/episodes/status/' + episode_id + '/' + is_active,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                if (is_active == 1) {
                                    $("#"+episode_id).removeClass('fa-toggle-off');
                                    $("#"+episode_id).addClass('fa-toggle-on');
                                    $("#"+episode_id).attr('title', 'Block');
                                } else {
                                    $("#"+episode_id).removeClass('fa-toggle-on');
                                    $("#"+episode_id).addClass('fa-toggle-off');
                                    $("#"+episode_id).attr('title', 'Unblock');
                                }
                            }
                        }).done(function (data) {

                        });
                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                    callback: function () {
                        $('#' + episode_id).attr('status', old_is_active);
                        if (old_is_active == 1) {
                            $('#' + episode_id).removeClass('fa-toggle-off');
                            $('#' + episode_id).addClass('fa-toggle-on');
                            $("#"+episode_id).attr('title', 'Block');
                        } else {
                            $('#' + episode_id).removeClass('fa-toggle-on');
                            $('#' + episode_id).addClass('fa-toggle-off');
                            $("#"+episode_id).attr('title', 'Unblock');
                        }
                    }
                }
            }
        });
    });

    $(document).on("click", ".deleteEpisode", function () {
        var old_is_active = $(this).attr('status');
        var episode_id = $(this).attr('episode-id');

        var is_active = 2;
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };

        bootbox.dialog({
            message: "Are you sure you want to delete this episode?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/episodes/status/' + episode_id + '/' + is_active,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false
                        }).done(function (data) {
                            //customDataTable(sAjaxSource, aoColumns);
                            window.location = '/episodes/';
                        });

                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                }
            }
        });
    });
});

$("#video_link").blur(function () {
	$('#duration').val('');
	$('.video-preview').attr('src','');
	$('#video_link').css({'border':'1px solid #ccc','background-color':'#fff'});
	
	var urlResult = validateVideoLink($('#video_link').val());
    if (0 != urlResult) {        
        $('.video-preview').attr('src', 'https://www.youtube.com/embed/' + urlResult);
		var app_key = 'AIzaSyDYwPzLevXauI-kTSVXTLroLyHEONuF9Rw';
		var url1 = "https://www.googleapis.com/youtube/v3/videos?id=" + urlResult + "&key=" + app_key + "&part=snippet,contentDetails";
		$.ajax({
			async: false,
			type: 'GET',
			url: url1,
			success: function (data) {
				if (data.items.length > 0) {
					var output = convert_time(data.items[0].contentDetails.duration);
					$('#duration').val(output);
				}
			}
		});
	}else {
        $('#video_link').css({'border':'2px solid red','background-color':'#f99595'});
		return false;
	}
	
});
$("#audio_link").blur(function () {
	$('.audio-preview').html('');
	$('#audio_link	').css({'border':'1px solid #ccc','background-color':'#fff'});
	
	var url = $('#audio_link').val();
	if( undefined != url && '' != url){ 
		var urlResult = validateAudioLink(url);

		if (0 != urlResult) {		
			$('.audio-preview').html(urlResult);
		}else {
			$('#audio_link').css({'border':'2px solid red','background-color':'#f99595'});
			return false;
		}
	}
});

function convert_time(duration) {
    var a = duration.match(/\d+/g);

    if (duration.indexOf('M') >= 0 && duration.indexOf('H') == -1 && duration.indexOf('S') == -1) {
        a = [0, a[0], 0];
    }

    if (duration.indexOf('H') >= 0 && duration.indexOf('M') == -1) {
        a = [a[0], 0, a[1]];
    }
    if (duration.indexOf('H') >= 0 && duration.indexOf('M') == -1 && duration.indexOf('S') == -1) {
        a = [a[0], 0, 0];
    }

    duration = 0;

    if (a.length == 3) {
        duration = duration + parseInt(a[0]) * 3600;
        duration = duration + parseInt(a[1]) * 60;
        duration = duration + parseInt(a[2]);
    }

    if (a.length == 2) {
        duration = duration + parseInt(a[0]) * 60;
        duration = duration + parseInt(a[1]);
    }

    if (a.length == 1) {
        duration = duration + parseInt(a[0]);
    }
    var h = Math.floor(duration / 3600);
    var m = Math.floor(duration % 3600 / 60);
    var s = Math.floor(duration % 3600 % 60);
    return ((h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s);
}

function validUrls(eventObj){
	$('#video_link').css({'border':'1px solid #ccc','background-color':'#fff'});
	$('#audio_link').css({'border':'1px solid #ccc','background-color':'#fff'});

	var videoUrlResult = validateVideoLink($('#video_link').val());
    if (0 == videoUrlResult){
		$('.video-preview').attr('src','');
        $('#video_link').css({'border':'2px solid red','background-color':'#f99595'});
		eventObj.preventDefault();
		return false;
	}else {
		$('#video_link').val('https://www.youtube.com/embed/' + videoUrlResult);
	}
	var url = $('#audio_link').val();
	if(undefined != url && '' != url) {
		var audioUrlResult = validateAudioLink(url);
		if ( 0 == audioUrlResult) {
			$('.audio-preview').html('');
			$('#audio_link').css({'border':'2px solid red','background-color':'#f99595'});
			eventObj.preventDefault();
			return false;
		}
	//	else {
	//		$('#audio_link').val($('.audio-preview>iframe').attr('src'));
	//	}
	}
	return true;
}

function validateVideoLink(url){
	/* Video Link Validation
	* Supported URL's 
	*	-https://www.youtube.com/watch?v=wWcXXxVZF2A
	*	-https://youtu.be/wWcXXxVZF2A
	*	-https://www.youtube.com/embed/wWcXXxVZF2A
	*/
	var result = 0;
	if (url != undefined && url != '') {        
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
		if(match==null || match[2].length != 11){
			result = 0;
        }else{
			result = match[2]; // video id
		}
	}
	return result;
}

function validateAudioLink(url){
	/*
	 * Audio Link Validation
	 * Supported Url - Any soundcloud link
	 * eg.https://soundcloud.com/abdullah-baig-2/kala-chashma-baar-baar-dekho-amar-arshi-badshah-neha-kakkar
	 */
	var result = 0;
	if (url != undefined && url != '') {		
		$.ajax({
			"async": false,
//			"crossDomain": true,
			"url": "http://soundcloud.com/oembed",
			"method": "POST",
			"data": {
			  "format": "json",
			  "url": url,
			  "width":560,
			  "height":315,
			  "auto_play": true
			},
			success:function(res){
				result = res.html;
			}
		});
	}
	return result;
}