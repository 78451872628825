$(".aboutUsContentDataTable").show(function () {
    var sAjaxSource = '/about-us-content/listing';
    var aoColumns = [
        {
            "sWidth": "4%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count",
        },
        {
            "sWidth": "20%",
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "title",
        },
        {
            "sWidth": "20%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "subtitle"
        },
        {
            "sWidth": "15%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "id",
            "render": function (mData, type, row) {
                var htmlBtn = '<a title="Edit" class="action-icons" href="/about-us-content/'+row.id+'/edit"><i class="fa fa-edit"></i></a> ' +
                    '<a title="Delete" class="action-icons" href="#"><i about-id="' + row.id + '" class="fa fa-trash deleteAboutUs"></i></a>';
                return htmlBtn;
            }
        }
    ];
    customDataTable(sAjaxSource,aoColumns);
    $("#create-new").insertBefore($(".dataTables_filter"));
    $('#create-new,.dataTables_filter').wrapAll('<div class="dataTables_wrapper">');
    
    
    $(document).on("click", ".deleteAboutUs", function () {
        var old_is_active = $(this).attr('status');
        var about_id = $(this).attr('about-id');

        var is_active = 2;
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        bootbox.dialog({
            message: "Are you sure you want to delete this content?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/about-us-content/status/' + about_id + '/' + is_active,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false
                        }).done(function (data) {
                            //customDataTable(sAjaxSource, aoColumns);
                            window.location = '/about-us-content/';
                        });

                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                }
            }
        });

    });
});