$(".categoriesDataTable").show(function () {
    var sAjaxSource = '/categories/listing';
    var aoColumns = [
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count",
        },
        {
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "name",
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "is_active",
            "render": function (mData, type, row) {
                if (mData == true) {
                    var htmlBtn = '<a title="Edit" class="action-icons" href="/categories/' + row.id + '/edit"><i class="fa fa-edit"></i></a> ' +
                            '<a title="Block" class="action-icons" href="javascript:void(0)"><i id ="' + row.id + '"  category-id ="' + row.id + '" status="1" class="fa fa-toggle-on blockUnblock"></i></a> ' +
                            '<a title="Down" class="action-icons" href="/categories/set-order/' + row.id + '/down"><i class="glyphicon glyphicon-menu-down"></i></a> ' +
                            '<a title="Up" class="action-icons" href="/categories/set-order/' + row.id + '/up"><i class="glyphicon glyphicon-menu-up"></i></a> ';
                } else if (mData == false) {
                    var htmlBtn = '<a title="Edit" class="action-icons" href="/categories/' + row.id + '/edit"><i class="fa fa-edit"></i></a> ' +
                            '<a title="Unblock" class="action-icons" href="javascript:void(0)"><i id ="' + row.id + '" category-id ="' + row.id + '" status="0" class="fa fa-toggle-off blockUnblock"></i></a> ' +
                            '<a title="Down" class="action-icons" href="/categories/set-order/' + row.id + '/down"><i class="glyphicon glyphicon-menu-down"></i></a> ' +
                            '<a title="Up" class="action-icons" href="/categories/set-order/' + row.id + '/up"><i class="glyphicon glyphicon-menu-up"></i></a> ';
                }
                return htmlBtn;
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns);
    $("#create-new").insertBefore($(".dataTables_filter"));
    $('#create-new,.dataTables_filter').wrapAll('<div class="dataTables_wrapper">');

    $(document).on("click", ".blockUnblock", function () {
        var old_is_active = $(this).attr('status');
        var category_id = $(this).attr('category-id');

        var is_active = 1;
        var status = 'unblock';

        if (old_is_active == 1) {
            is_active = 0;
            var status = 'block';
        }
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        $(this).attr('status', is_active);

        bootbox.dialog({
            message: "Are you sure you want to " + status + " this category?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/categories/status/' + category_id + '/' + is_active,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                if (is_active == 1) {
                                    $("#"+category_id).removeClass('fa-toggle-off');
                                    $("#"+category_id).addClass('fa-toggle-on');
                                    $("#"+category_id).attr('title', 'Block');
                                } else {
                                    $("#"+category_id).removeClass('fa-toggle-on');
                                    $("#"+category_id).addClass('fa-toggle-off');
                                    $("#"+category_id).attr('title', 'Unblock');
                                }
                            }
                        }).done(function (data) {

                        });
                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                    callback: function () {
                        $('#' + category_id).attr('status', old_is_active);
                        if (old_is_active == 1) {
                            $('#' + category_id).removeClass('fa-toggle-off');
                            $('#' + category_id).addClass('fa-toggle-on');
                            $("#"+category_id).attr('title', 'Block');
                        } else {
                            $('#' + category_id).removeClass('fa-toggle-on');
                            $('#' + category_id).addClass('fa-toggle-off');
                            $("#"+category_id).attr('title', 'Unblock');
                        }
                    }
                }
            }
        });

    });

    $(document).on("click", ".deleteCategory", function () {
        var old_is_active = $(this).attr('status');
        var category_id = $(this).attr('category-id');

        var is_active = 2;
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        bootbox.dialog({
            message: "Are you sure you want to delete this category?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/categories/status/' + category_id + '/' + is_active,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false
                        }).done(function (data) {
                            //customDataTable(sAjaxSource, aoColumns);
                            window.location = '/categories/';
                        });

                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                }
            }
        });

    });
});