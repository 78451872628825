$(".configsDataTable").show(function () {
    var sAjaxSource = '/configs/listing';
    var aoColumns = [
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count",
        },
        {
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "key",
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "value"
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": null,
            "render": function (mData, type, row) {
                return '<a title="Edit" class="action-icons" href="/configs/' + row.id + '/edit"><i class="fa fa-edit"></i></a>';
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns);
    $('#create-new,.dataTables_filter').wrapAll('<div class="dataTables_wrapper">');

});

