$(".joinHandsDataTable").show(function () {
    var sAjaxSource = '/join-hands/listing';
    var aoColumns = [
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count"
        },
        {
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "title"
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "id",
            "render": function (mData, type, row) {
                var htmlBtn = '<a title="Edit" class="action-icons" href="/join-hands/'+row.id+'/edit"><i class="fa fa-edit"></i></a>';
                return htmlBtn;
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns, null, false, false);
});

$('#media_link').on('change', function () {
    var file = document.querySelector('#media_link').files[0];
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        document.getElementById("image").src = reader.result;
    }, false);
    if (file) {
        reader.readAsDataURL(file);
    }
});