
$(".userDataTable").show(function () {
    var sAjaxSource = 'users/listing';
    var aoColumns = [
        {
            "sWidth": "4%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count",
        },
        {
            "sWidth": "20%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "first_name"
        },
        {
            "sWidth": "20%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "email"
        },
        {
            "sWidth": "11%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "birth_date"
        },
        {
            "sWidth": "11%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "is_subscribe",
            "render": function (
                mData, type,
                row) {
                if (mData == true) {
                    var title = 'Subscribed';
                    var className = 'fa-check-square-o';
                    var status = 1;
                } else if (mData == false) {
                    var title = 'Unsubscribed';
                    var className = 'fa-square-o';
                    var status = 0;
                }
                return '<a class="action-icons"  href="#"><i id="subscribe_' + row.id + '" status="' + status + '" title="' + title + '" user-id ="' + row.id + '" class="fa ' + className + '"></i></a>';
            }
        },
        {
            "sWidth": "15%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "is_active",
            "render": function (
                    mData, type,
                    row) {
                if (mData == true) {
                    var title = 'Block';
                    var className = 'fa-toggle-on';
                    var status = 1;
                } else if (mData == false) {
                    var title = 'Unblock';
                    var className = 'fa-toggle-off';
                    var status = 0;
                }
                return '<a class="action-icons viewUser" title="View" data-toggle="modal" data-target="#viewUsers" user-id ="' + row.id + '"   href="#"><i class="fa fa-th-large"></i></a>' +
                    '<a class="action-icons"  href="#"><i id="' + row.id + '" status="' + status + '" title="' + title + '" user-id ="' + row.id + '" class="fa ' + className + ' blockUnblock"></i></a>';
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns);

    $(document).on("click", ".blockUnblock", function () {
        var old_is_active = $(this).attr('status');
        var user_id = $(this).attr('user-id');

        var is_active = 1;
        var status = 'unblock';

        if (old_is_active == 1) {
            is_active = 0;
            var status = 'block';
        }
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        $(this).attr('status', is_active);

        bootbox.dialog({
            message: "Are you sure you want to " + status + " this user?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/users/status/' + user_id + '/' + is_active,
                            //data: requestData,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                console.log('1');
                                console.log(is_active);
                                if (is_active == 1) {
                                    $('#'+user_id).removeClass('fa-toggle-off');
                                    $('#'+user_id).addClass('fa-toggle-on');
                                    $("#"+user_id).attr('title', 'Block');
                                } else {
                                    $('#'+user_id).removeClass('fa-toggle-on');
                                    $('#'+user_id).addClass('fa-toggle-off');
                                    $("#"+user_id).attr('title', 'Unblock');
                                }
                            }
                        }).done(function (data) {

                        });
                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                    callback: function () {
                        $('#'+user_id).attr('status', old_is_active);
                        if (old_is_active == 1) {
                            $('#'+user_id).removeClass('fa-toggle-off');
                            $('#'+user_id).addClass('fa-toggle-on');
                            $("#"+user_id).attr('title', 'Block');
                        } else {
                            $('#'+user_id).removeClass('fa-toggle-on');
                            $('#'+user_id).addClass('fa-toggle-off');
                            $("#"+user_id).attr('title', 'Unblock');
                        }
                    }
                }
            }
        });
    });
});

$(document).on("click", ".viewUser", function () {
    var userId = $(this).attr('user-id');
    var bModal = $('#updateemp');

    var header = {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
    };
    $.ajax({
        url: '/users/' + userId,
        headers: header,
        type: "GET",
        contentType: false,
        processData: false
    }).done(function (data) {
        $('#name').val(data.details.name);
        $('#email').val(data.details.email);
        $('#gender').val(data.details.gender);
        $('#location').val(data.details.location);
        $('#birth_date').val(data.details.birth_date);
    });
});


