$(".socialLinksDataTable").show(function () {

    var sAjaxSource = 'social-links/listing';
    var aoColumns = [
        {
            "sWidth": "4%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count",
        },
        {
            "sWidth": "20%",
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "title",
        },
        {
            "sWidth": "20%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "link"
        },
        {
            "sWidth": "15%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "action",
            "render": function (
                    mData, type,
                    row) {
                return '<a title="Edit" class="action-icons" href="/social-links/' + row.id + '/edit"><i class="fa fa-edit"></i></a>';
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns, null, false, false);
    //$("#create-new").insertBefore($(".dataTables_filter"));
});
