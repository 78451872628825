$(".chart-div").show(function () {

    var theme = {
        color: [
            '#9B59B6', '#8abb6f', '#26B99A', '#BDC3C7', '#34495E', '#759c6a', '#bfd3b7'
                    , '#3498DB'

        ],
        title: {
            itemGap: 8,
            textStyle: {
                fontWeight: 'normal',
                color: '#408829'
            }
        },
        dataRange: {
            color: ['#1f610a', '#97b58d']
        },
        toolbox: {
            color: ['#408829', '#408829', '#408829', '#408829']
        },
        tooltip: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            axisPointer: {
                type: 'line',
                lineStyle: {
                    color: '#408829',
                    type: 'dashed'
                },
                crossStyle: {
                    color: '#408829'
                },
                shadowStyle: {
                    color: 'rgba(200,200,200,0.3)'
                }
            }
        },
        dataZoom: {
            dataBackgroundColor: '#eee',
            fillerColor: 'rgba(64,136,41,0.2)',
            handleColor: '#408829'
        },
        grid: {
            borderWidth: 0
        },
        categoryAxis: {
            axisLine: {
                lineStyle: {
                    color: '#408829'
                }
            },
            splitLine: {
                lineStyle: {
                    color: ['#eee']
                }
            }
        },
        valueAxis: {
            axisLine: {
                lineStyle: {
                    color: '#408829'
                }
            },
            splitArea: {
                show: true,
                areaStyle: {
                    color: ['rgba(250,250,250,0.1)', 'rgba(200,200,200,0.1)']
                }
            },
            splitLine: {
                lineStyle: {
                    color: ['#eee']
                }
            }
        },
        timeline: {
            lineStyle: {
                color: '#408829'
            },
            controlStyle: {
                normal: {color: '#408829'},
                emphasis: {color: '#408829'}
            }
        },
        k: {
            itemStyle: {
                normal: {
                    color: '#68a54a',
                    color0: '#a9cba2',
                    lineStyle: {
                        width: 1,
                        color: '#408829',
                        color0: '#86b379'
                    }
                }
            }
        },
        map: {
            itemStyle: {
                normal: {
                    areaStyle: {
                        color: '#ddd'
                    },
                    label: {
                        textStyle: {
                            color: '#c12e34'
                        }
                    }
                },
                emphasis: {
                    areaStyle: {
                        color: '#99d2dd'
                    },
                    label: {
                        textStyle: {
                            color: '#c12e34'
                        }
                    }
                }
            }
        },
        force: {
            itemStyle: {
                normal: {
                    linkStyle: {
                        strokeColor: '#408829'
                    }
                }
            }
        },
        chord: {
            padding: 4,
            itemStyle: {
                normal: {
                    lineStyle: {
                        width: 1,
                        color: 'rgba(128, 128, 128, 0.5)'
                    },
                    chordStyle: {
                        lineStyle: {
                            width: 1,
                            color: 'rgba(128, 128, 128, 0.5)'
                        }
                    }
                },
                emphasis: {
                    lineStyle: {
                        width: 1,
                        color: 'rgba(128, 128, 128, 0.5)'
                    },
                    chordStyle: {
                        lineStyle: {
                            width: 1,
                            color: 'rgba(128, 128, 128, 0.5)'
                        }
                    }
                }
            }
        },
        gauge: {
            startAngle: 225,
            endAngle: -45,
            axisLine: {
                show: true,
                lineStyle: {
                    color: [[0.2, '#86b379'], [0.8, '#68a54a'], [1, '#408829']],
                    width: 8
                }
            },
            axisTick: {
                splitNumber: 10,
                length: 12,
                lineStyle: {
                    color: 'auto'
                }
            },
            axisLabel: {
                textStyle: {
                    color: 'auto'
                }
            },
            splitLine: {
                length: 18,
                lineStyle: {
                    color: 'auto'
                }
            },
            pointer: {
                length: '90%',
                color: 'auto'
            },
            title: {
                textStyle: {
                    color: '#333'
                }
            },
            detail: {
                textStyle: {
                    color: 'auto'
                }
            }
        },
        textStyle: {
            fontFamily: 'Arial, Verdana, sans-serif'
        }
    };

    var chart_view = 'month';

    Chart.defaults.global.legend = {
        enabled: false
    };
    registered_users(chart_view);
    $('.x_title h2').text($("#chart-type option:selected").text());

    var header = {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
    };
    $("input[name=chart_view]").change(function () { 
        chart_view = $("input[name=chart_view]:checked").val();
        if ($("#chart-type option:selected").text() == 'Registered Users') {
            registered_users(chart_view);
        } else if ($("#chart-type option:selected").text() == 'Requests') {
            requests(chart_view);
        } else if ($("#chart-type option:selected").text() == 'Episodes') { 
            episodes(chart_view);
        } else if ($("#chart-type option:selected").text() == 'Category wise Episodes') {
            category_episodes(chart_view);
        }

    });

    // Registered Users Chart
    function registered_users(chart_view) {
// Line chart
        $.ajax({
            url: '/get-users/' + chart_view,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false,
            success: function (data) {
            }
        }).done(function (data) {
            var year = new Date().getFullYear();
            if (chart_view == 'month') {
                var dataArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                $.each(data.details, function (key, value) {
                    dataArr[key - 1] = value.length;
                });
                var labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            } else if (chart_view == 'year') {

                var lastYear = year - 3;
                var labels = [];
                for (var i = lastYear; i <= year; i++) {
                    labels.push(i);
                }
                var dataArr = [0, 0, 0, 0];

                $.each(data.details, function (key, value) {
                    for (var j = 0; j <= dataArr.length; j++) {
                        if (key == labels[j]) {
                            dataArr[j] = value.length;
                        }
                    }
                });
                $('.x_title h2').text("Registered Users");

            }
            var echartLine = echarts.init(document.getElementById('echart_line'), theme);

            echartLine.setOption({
                title: {
                    text: '',
                    subtext: ''
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    x: 220,
                    y: 40,
                    data: ['Users']
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: {
                            show: true,
                            title: {
                                line: 'Line',
                                bar: 'Bar',
                                stack: 'Stack',
                                tiled: 'Tiled'
                            },
                            type: ['line', 'bar', 'stack', 'tiled']
                        },
                        restore: {
                            show: true,
                            title: "Restore"
                        },
                        saveAsImage: {
                            show: true,
                            title: "Save Image"
                        }
                    }
                },
                calculable: true,
                xAxis: [{
                        type: 'category',
                        boundaryGap: false,
                        data: labels
                    }],
                yAxis: [{
                        type: 'value'
                    }],
                series: [{
                        name: 'Users',
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            normal: {
                                areaStyle: {
                                    type: 'default'
                                }
                            }
                        },
                        data: dataArr
                    }]
            });
        });
    }

// Requests Chart
    function episodes(chart_view) { 
        $.ajax({
            url: '/get-episodes/' + chart_view,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false,
            success: function (data) {
            }
        }).done(function (data) {
            var year = new Date().getFullYear();
            if (chart_view == 'month') {
                var dataArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                $.each(data.details, function (key, value) {
                    dataArr[key - 1] = value.length;
                });
                var labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            } else if (chart_view == 'year') {

                var lastYear = year - 3;
                var labels = [];
                for (var i = lastYear; i <= year; i++) {
                    labels.push(i);
                }
                var dataArr = [0, 0, 0, 0];

                $.each(data.details, function (key, value) {
                    for (var j = 0; j <= dataArr.length; j++) {
                        if (key == labels[j]) {
                            dataArr[j] = value.length;
                        }
                    }
                });
                $('.x_title h2').text("Episodes");

            }
            var echartLine = echarts.init(document.getElementById('echart_line'), theme);

            echartLine.setOption({
                title: {
                    text: '',
                    subtext: ''
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    x: 220,
                    y: 40,
                    data: ['Episode']
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: {
                            show: true,
                            title: {
                                line: 'Line',
                                bar: 'Bar',
                                stack: 'Stack',
                                tiled: 'Tiled'
                            },
                            type: ['line', 'bar', 'stack', 'tiled']
                        },
                        restore: {
                            show: true,
                            title: "Restore"
                        },
                        saveAsImage: {
                            show: true,
                            title: "Save Image"
                        }
                    }
                },
                calculable: true,
                xAxis: [{
                        type: 'category',
                        boundaryGap: false,
                        data: labels
                    }],
                yAxis: [{
                        type: 'value'
                    }],
                series: [{
                        name: 'Episode',
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            normal: {
                                areaStyle: {
                                    type: 'default'
                                }
                            }
                        },
                        data: dataArr
                    }]
            });
        });
    }


// Requests Chart
    function requests(chart_view) {
// Bar chart
        $.ajax({
            url: '/get-requests/' + chart_view,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false,
            success: function (data) {
            }
        }).done(function (data) {
            var year = new Date().getFullYear();
            if (chart_view == 'month') {
                var dataArrContact = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                var dataArrJoin = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                var dataArrSubs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                $.each(data.details.contact_us, function (key, value) {
                    dataArrContact[key - 1] = value.length;
                });
                $.each(data.details.join_hands_with_us, function (key, value) {
                    dataArrJoin[key - 1] = value.length;
                });
                $.each(data.details.subscription, function (key, value) {
                    dataArrSubs[key - 1] = value.length;
                });
                var labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            } else if (chart_view == 'year') {

                var lastYear = year - 3;
                var labels = [];
                for (var i = lastYear; i <= year; i++) {
                    labels.push(i);
                }
                var dataArrContact = [0, 0, 0, 0];
                var dataArrJoin = [0, 0, 0, 0];
                var dataArrSubs = [0, 0, 0, 0];

                $.each(data.details.contact_us, function (key, value) {
                    for (var j = 0; j <= dataArrContact.length; j++) {
                        if (key == labels[j]) {
                            dataArrContact[j] = value.length;
                        }
                    }
                });
                $.each(data.details.join_hands_with_us, function (key, value) {
                    for (var j = 0; j <= dataArrJoin.length; j++) {
                        if (key == labels[j]) {
                            dataArrJoin[j] = value.length;
                        }
                    }
                });
                $.each(data.details.subscription, function (key, value) {
                    for (var j = 0; j <= dataArrSubs.length; j++) {
                        if (key == labels[j]) {
                            dataArrSubs[j] = value.length;
                        }
                    }
                });
                $('.x_title h2').text("Requests");

            }
            var echartLine = echarts.init(document.getElementById('echart_line'), theme);

            echartLine.setOption({
                title: {
                    text: '',
                    subtext: ''
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    x: 220,
                    y: 40,
                    data: ['Contact Us', 'Join Hands With Us', 'Subscription']
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: {
                            show: true,
                            title: {
                                line: 'Line',
                                bar: 'Bar',
                                stack: 'Stack',
                                tiled: 'Tiled'
                            },
                            type: ['line', 'bar', 'stack', 'tiled']
                        },
                        restore: {
                            show: true,
                            title: "Restore"
                        },
                        saveAsImage: {
                            show: true,
                            title: "Save Image"
                        }
                    }
                },
                calculable: true,
                xAxis: [{
                        type: 'category',
                        boundaryGap: false,
                        data: labels
                    }],
                yAxis: [{
                        type: 'value'
                    }],
                series: [{
                        name: 'Contact Us',
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            normal: {
                                areaStyle: {
                                    type: 'default'
                                }
                            }
                        },
                        data: dataArrContact
                    }, {
                        name: 'Join Hands With Us',
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            normal: {
                                areaStyle: {
                                    type: 'default'
                                }
                            }
                        },
                        data: dataArrJoin
                    }, {
                        name: 'Subscription',
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            normal: {
                                areaStyle: {
                                    type: 'default'
                                }
                            }
                        },
                        data: dataArrSubs
                    }]
            });
        });
    }

    // Requests Chart
    function category_episodes(chart_view) {
// Bar chart
        $.ajax({
            url: '/get-category-episodes/',
            headers: header,
            type: "GET",
            contentType: false,
            processData: false,
            success: function (data) {
            }
        }).done(function (data) {
            var dataArr = [];
            var labels = Object.keys(data.details);
            //console.log(labels);
            for (var i = 0; i < labels.length; i++) {
                if(labels[i]=='Funding'){
                    dataArr[i] = data.details.Funding.length;
                }else if(labels[i]=='Technology'){
                    dataArr[i] = data.details.Technology.length;
                }else if(labels[i]=='Startup'){
                    dataArr[i] = data.details.Startup.length;
                }else if(labels[i]=='Investment'){
                    dataArr[i] = data.details.Investment.length;
                }
                
                console.log(labels[i]);
            }
            var echartLine = echarts.init(document.getElementById('echart_line'), theme);

            echartLine.setOption({
                title: {
                    text: '',
                    subtext: ''
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    x: 220,
                    y: 40,
                    data: ['Episodes']
                },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: {
                            show: true,
                            title: {
                                line: 'Line',
                                bar: 'Bar',
                                stack: 'Stack',
                                tiled: 'Tiled'
                            },
                            type: ['line', 'bar', 'stack', 'tiled']
                        },
                        restore: {
                            show: true,
                            title: "Restore"
                        },
                        saveAsImage: {
                            show: true,
                            title: "Save Image"
                        }
                    }
                },
                calculable: true,
                xAxis: [{
                        type: 'category',
                        boundaryGap: false,
                        data: labels
                    }],
                yAxis: [{
                        type: 'value'
                    }],
                series: [{
                        name: 'Episodes',
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            normal: {
                                areaStyle: {
                                    type: 'default'
                                }
                            }
                        },
                        data: dataArr
                    }]
            });
        });
    }
    $("#chart-type").change(function () { 
        chart_view = 'month';
        $("input[name=chart_view][value='month']").prop("checked", true);
        $('.x_title h2').text($("#chart-type option:selected").text());
        if ($("#chart-type option:selected").text() == 'Registered Users') {
            $('.radio_option_wrap').show();
            registered_users(chart_view);
        } else if ($("#chart-type option:selected").text() == 'Requests') {
            $('.radio_option_wrap').show();
            requests(chart_view);
        } else if($("#chart-type option:selected").text() == 'Episodes') {
            $('.radio_option_wrap').show();
            episodes(chart_view);
        } else if($("#chart-type option:selected").text() == 'Category wise Episodes') {
            $('.radio_option_wrap').hide();
            category_episodes(chart_view);
        }

    });
});