$(".tagsDataTable").show(function () {
    var sAjaxSource = '/tags/listing';
    var aoColumns = [
        {
            "sWidth": "4%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count",
        },
        {
            "sWidth": "20%",
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "name",
        },
        {
            "sWidth": "15%",
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "is_active",
            "render": function (mData, type, row) {
                if (mData == true) {
                    var htmlBtn = '<a title="Edit" class="action-icons" href="/tags/' + row.id + '/edit"><i class="fa fa-edit"></i></a> ' +
                        '<a title="Delete" class="action-icons" href="javascript:void(0)"><i tag-id="' + row.id + '" class="fa fa-trash deleteTag"></i></a>';
                } else if (mData == false) {
                    var htmlBtn = '<a title="Edit" class="action-icons" href="/tags/' + row.id + '/edit"><i class="fa fa-edit"></i></a> ' +
                        '<a title="Delete" class="action-icons" href="javascript:void(0)"><i tag-id="' + row.id + '" class="fa fa-trash deleteTag"></i></a>';
                }
                return htmlBtn;
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns);
    $("#create-new").insertBefore($(".dataTables_filter"));
    $('#create-new,.dataTables_filter').wrapAll('<div class="dataTables_wrapper">');

    $(document).on("click", ".deleteTag", function () {
        var old_is_active = $(this).attr('status');
        var tag_id = $(this).attr('tag-id');

        var is_active = 2;
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        bootbox.dialog({
            message: "Are you sure you want to delete this tag?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/tags/status/' + tag_id + '/' + is_active,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false
                        }).done(function (data) {
                            //customDataTable(sAjaxSource, aoColumns);
                            window.location = '/tags/';
                        });

                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                }
            }
        });

    });
});