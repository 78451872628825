$(".bannersDataTable").show(function () {
    var sAjaxSource = '/banners/listing?media_type=' + $('#media_type').val();
    var aoColumns = [
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "count",
        },
        {
            "bSortable": false,
            "sClass": "a-Left td-wrap",
            "mData": "title",
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "description"
        },
        {
            "bSortable": false,
            "sClass": "a-Left",
            "mData": "action",
            "render": function (
                    mData, type,
                    row) {
                return '<a title="Edit" class="action-icons" href="/banners/' + row.id + '/edit"><i class="fa fa-edit"></i></a>' +
                        '<a title="Delete" class="action-icons delete-record" href="#" banner-id ="' + row.id + '"><i class="fa fa-trash"></i></a>';
            }
        }
    ];
    customDataTable(sAjaxSource, aoColumns, null, false, false);
    $("#create-new").insertBefore($(".dataTables_filter"));
    $('#create-new,.dataTables_filter').wrapAll('<div class="dataTables_wrapper">');

    $(document).on("click", ".activeDeactive", function () {
        if ($(this).prop("checked") == true) {
            var is_active = 1;
        } else if ($(this).prop("checked") == false) {
            var is_active = 0;
        }
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        $.ajax({
            url: '/banners/status/null/' + is_active + '/type/' + $('#media_type').val(),
            //data: requestData,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false
        }).done(function (data) {

        });

    });

    $(document).on("click", ".delete-record", function () {
        var old_is_active = $(this).attr('status');
        var banner_id = $(this).attr('banner-id');
        var media_type = $('#media_type').val();

        var is_active = 2;
        var header = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
        };
        bootbox.dialog({
            message: "Are you sure you want to delete this " + media_type + "?",
            title: "Confirm",
            closeButton: false,
            buttons: {
                success: {
                    label: "OK",
                    className: "btn-success",
                    callback: function () {
                        $.ajax({
                            url: '/banners/status/' + banner_id + '/' + is_active + '/type/null',
                            //data: requestData,
                            headers: header,
                            type: "GET",
                            contentType: false,
                            processData: false
                        }).done(function (data) {
                            //customDataTable(sAjaxSource, aoColumns, null, false, false);
                            //$("#create-new").insertBefore($(".dataTables_filter"));
                            //$('#create-new,.dataTables_filter').wrapAll('<div class="dataTables_wrapper">');
                            if (media_type == 'image') {
                                window.location = '/banners/';
                            } else {
                                window.location = '/banners-video/';
                            }
                        });
                    }
                },
                danger: {
                    label: "Cancel",
                    className: "btn-danger",
                }
            }
        });

    });
});

$('#media_link').on('change', function () {
    $('#preview_image').html('<img class="video-preview" src=""  alt="Image preview..." width="50%" height="45%"  id="image"/>');
      var file = document.querySelector('#media_link').files[0];

      var reader  = new FileReader();
    reader.addEventListener("load", function () {
            document.getElementById("image").src = reader.result;
      }, false);
      if (file) {
            reader.readAsDataURL(file);
      }
});

$(document).on("click", ".image", function () {
    $('#image-div').show();
    $('#video-div').css('display', 'none');
    $(this).addClass('active');
    $('.video').removeClass('active');
});
$(document).on("click", ".video", function () {
    $('#video-div').show();
    $('#image-div').css('display', 'none');
    $(this).addClass('active');
    $('.image').removeClass('active');
});