$(".reqestsDataTable").show(function () {
    var sAjaxSource = 'requests/listing/' + $('#request li:first').attr('type');
    if ($('#request li:first').text() == 'Contact Us') {

        var aoColumns = [
            {
                "sWidth": "4%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "count",
            },
            {
                "sWidth": "10%",
                "bSortable": false,
                "sClass": "a-Left td-wrap",
                "mData": "name",
            },
            {
                "sWidth": "15%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "email",
            },
            {
                "sWidth": "35%",
                "bSortable": false,
                "sClass": "a-Left td-wrap",
                "mData": "message",
            },
            {
                "sWidth": "10%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "created"
            },
            {
                "sWidth": "10%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "action",
                "render": function (mData, type, row) {
                    return '<a class="action-icons viewRequest" title="View" data-toggle="modal" data-target="#view" request-id ="' + row.id + '"   href="#"><i class="fa fa-th-large"></i></a>';
                }
            }
        ];
        customDataTable(sAjaxSource, aoColumns, '#dataTable');
    } else {
        $('.contact').css('display', 'none');
        $('.other').show();
        var aoColumns = [
            {
                "sWidth": "4%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "count",
            },
            {
                "sWidth": "20%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "email",
            },
            {
                "sWidth": "20%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "created"
            }

        ];
        customDataTable(sAjaxSource, aoColumns, '#dataTables');
    }
    $('#request li:first').addClass('active');
});

$(document).on("click", ".request-tabs", function () {
    var type = $(this).attr('type');
    $('#request_type').val(type);
    $("#export-request").attr("href", "/requests/export/"+type);
    $('.request-tabs').removeClass('active');
    $(this).addClass('active');
    var sAjaxSource = 'requests/listing/' + type;
    if ($(this).text() == 'Contact Us') {
        $('.other').css('display', 'none');
        $('.contact').show();
        var aoColumns = [
            {
                "sWidth": "4%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "count",
            },
            {
                "sWidth": "20%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "name",
            },
            {
                "sWidth": "20%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "email",
            },
            {
                "sWidth": "20%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "message",
            },
            {
                "sWidth": "20%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "created"
            }

        ];
        window.location = '/requests/';
        //customDataTable(sAjaxSource, aoColumns, '#dataTable');

    } else {
        $('.contact').css('display', 'none');
        $('.other').show();
        var aoColumns = [
            {
                "sWidth": "4%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "count",
            },
            {
                "sWidth": "20%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "email",
            },
            {
                "sWidth": "20%",
                "bSortable": false,
                "sClass": "a-Left",
                "mData": "created"
            }

        ];
        customDataTable(sAjaxSource, aoColumns, '#dataTables');
    }
});

$(document).on("click", ".viewRequest", function () {
    var requestId = $(this).attr('request-id');
    var bModal = $('#updateemp');

    var header = {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), "Content-Type": 'application/json'
    };
    $.ajax({
        url: '/requests/' + requestId,
        //data: requestData,
        headers: header,
        type: "GET",
        contentType: false,
        processData: false
    }).done(function (data) {
        $('#name').val(data.details.name);
        $('#email').val(data.details.email);
        $('#phone').val(data.details.phone);
        $('#location').val(data.details.location);
        $('#message').val(data.details.message);
    });
});

